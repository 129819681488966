import { render, staticRenderFns } from "./CoreBlockForm.vue?vue&type=template&id=acd31a8a"
import script from "./CoreBlockForm.vue?vue&type=script&lang=js"
export * from "./CoreBlockForm.vue?vue&type=script&lang=js"
import style0 from "./CoreBlockForm.vue?vue&type=style&index=0&id=acd31a8a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreIconCircleCheck: require('/usr/src/app/Core.Library/src/components/icon/CoreIconCircleCheck.vue').default,CoreBlock: require('/usr/src/app/Core.Library/src/components/block/CoreBlock.vue').default,CoreIconError: require('/usr/src/app/Core.Library/src/components/icon/CoreIconError.vue').default,CoreBlockSelect: require('/usr/src/app/Core.Library/src/components/block/CoreBlockSelect.vue').default,CoreIconInfo: require('/usr/src/app/Core.Library/src/components/icon/CoreIconInfo.vue').default})
